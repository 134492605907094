import { Link } from "gatsby"
import React from "react"
import { Button, Image } from "react-bootstrap"
import SEO from "../components/head/seo"
import NavigationBar from "../components/navbar/navigationBar"
import "./404.less"

const page404 = () => {
  return (
    <>
    <SEO/>
    <div className="error-container">
      <NavigationBar />
      <div className="content mb-2">
          <h1 className="display-1">404</h1>
          <h2 className="display-5">The page you are looking for couldn't found.</h2>
          <Button href="/" className="homepage-btn">
            Homepage
          </Button>
      </div>
    </div>
    </>
  )
}

export default page404
